.home-business-topbar {
    .social-icon-style-01 {
        li {
            a {
                color: #828282;
            }
        }
    }
}
.home-business-piechart {
    .piechart-style-02 {
        .piechart-vertical {
            p {
                width: 80%;
            }
        }
    }
}
