.overline-icon-box {
  border-color: var(--base-color, #0038E3);
  text-align: center;
  z-index: 1;
  position: relative;
  padding: 63px 56px;
  border-top-width: 4px;
  border-top-style: solid;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 25px 0px;
  transition: var(--default-transition);
  transition-duration: 0.4s;
  @apply md:p-[36px] sm:p-[55px];

  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    transform: translate3d(0, -3px, 0);
  }

  .feature-box-icon {
    i {
      color: var(--base-color, #0038e3);
      display: block;
      font-size: 50px;
      @apply mb-[35px] md:mb-[15px];
    }
  }

  .feature-box-content {
    span {
      @apply font-serif text-xmd font-medium text-darkgray md:w-[98%];
      margin-bottom: 10px;
      display: block;
    }
  }
}
